import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Dropdown } from 'react-bootstrap';

// import images
import verticalicon from '../.././../images/verticalicon.svg';
import axios from 'axios';
import { API_BASE_URL, getBearerToken } from '../../../functions';
import ToastMessage from '../../ToastMessage';
import toast from 'react-hot-toast';
import ConfirmationPopup from '../../Modals/ConfirmationPopup';

const GroupTRLoop = ({ groups }) => {

    const [toastMessage, setToastMessage] = useState(null);
    const [show, setShow] = useState('show');
    const [targetBooking, setTargetBooking] = useState({ id: '', status: '' })
    const [confirmation, setConfirmation] = useState({ show: false, target: '' })


    const handalDelet = (e, id) => {
        axios.get(`${API_BASE_URL}/deletegroup/${e}`, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        }).then(response => {
            // Handle the response data
            toast.success(response.data.message);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }).catch(error => {
            // Handle errors
            toast.error(error.response.data.message);
        });

    };

    const handalClose = (e, target) => {
        setConfirmation({ show: true, target: 'status' })
        setTargetBooking({ id: e, status: target })
    };


    const handleCloseGroup = (e) => {
        e.preventDefault()
        setConfirmation({ show: false, target: '' })
        const tid = toast.loading("Please wait...");
        const fromdata = new FormData();
        fromdata.append('group_id', targetBooking.id)
        fromdata.append('close_status', targetBooking.status)

        axios.post(`${API_BASE_URL}/update-group-status`, fromdata, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        })
            // .then(response => response)
            .then(response => {
                toast.success(response.data.message);
                toast.dismiss(tid);
                // setToastMessage({ type: 'success', message: 'Ticket cancelled successfully!' });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch(error => {
                // Handle error
                toast.error(error.response.data.message, {
                    id: tid,
                });
            });

    }

    // console.log(toastMessage)
    return (
        <>
            {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={show} setShow={setShow} />}

            {groups ? groups?.map((val, i) => {
                return (
                    <tr key={i}>
                        <td>
                            <Link to={`/dashboard/pnr/${val.pnr}`} className={'text-decoration-none bluetext fontweight600'}>{val?.pnr}</Link>
                        </td>
                        <td>
                            {val?.subpart?.map((subpart, j) => (
                                <>
                                    {subpart?.departure_date}<br />
                                </>
                            ))}
                        </td>
                        <td>
                            {val?.subpart?.map((subpart, j) => (
                                <>
                                    {subpart?.departure_sector_name}<br />
                                </>
                            ))}
                        </td>
                        <td><Badge bg={''} className={'p-2 py-1 RGBAcolor3bg greentext'}>{val?.tickets_wehave}</Badge></td>
                        <td><Badge bg={''} className={'p-2 py-1 RGBAcolor3bg greentext'}>{val?.total_ticket}</Badge></td>
                        <td>{val?.price}</td>
                        <td>{val?.infant_price}</td>
                        <td>{val?.sold}</td>
                        <td>{val?.added_by?.name}</td>
                        <td>
                            <Badge className={`py-2 mt-2 ${val?.close_status === '1' ? 'errorlighterbg redtext' : 'successcolorbg whitetext'}`}>{val?.close_status === '1' ? 'Closed' : 'Open'}</Badge>
                        </td>
                        <td>
                            <Dropdown className={''} align={'end'} autoClose="outside">
                                <Dropdown.Toggle className={''} variant={''} id="dropdown-autoclose-outside"><img className={'img-fluid'} src={verticalicon} alt={'icon'} /></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Link to={`/dashboard/update-group/${val?.id}`} className={'dropdown-item'}>Edit Group</Link>
                                    <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalDelet(val?.id)}>Delete Group</Link>
                                    {val?.close_status == 0 ?
                                        <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalClose(val.id, 1)}>
                                            Close Group
                                        </Link>
                                        :
                                        <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalClose(val.id, 0)}>
                                            Active Group
                                        </Link>}
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                )
            })
                :
                <p>No group found</p>
            }

            {confirmation.target == 'status' &&
                <ConfirmationPopup show={confirmation} setShow={setConfirmation} handleConfirm={handleCloseGroup} />
            }
        </>
    )
}

export default GroupTRLoop;