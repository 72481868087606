import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, NavItem } from 'react-bootstrap';

const PartialPaid = (props) => {
  return (
    <>
      <Link className={'d-flex align-items-start nav-item'} to={`/dashboard/book/${props?.id}`}>
        <div className={'d-flex align-items-center'}>
          <div className={'AllPhoto'} style={{ borderRadius: '8px' }}><img className="img-fluid" src={props.avatarpic} alt="icon" /></div>
          <div className={'ms-3'}>
            <Link to={`/dashboard/book/${props?.id}`} className={'text-decoration-none mb-1 d-block blacktext'}><h6 className={'blacktext fontsize14 fontweight700'}>{props.title}</h6></Link>
            <p className={'fontsize14 paragraphtext mb-0 fontweight500'}>{props.content}</p>
          </div>
        </div>
        <div className="d-block text-center ms-auto">
          <p className={'mb-0 ms-auto fontsize12 paragraphtext'}>{props.date}</p>
          <Badge className={`py-2 mt-2 ${props.confirm === '1' ? 'successcolorbg whitetext' : 'errorlighterbg redtext'}`}>{props.confirm === '1' ? 'Confirmed' : 'Pending'}</Badge>
        </div>
      </Link>
    </>
  )
}

export default PartialPaid;