import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Dropdown, Row, Spinner, Table } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { API_BASE_URL, getBearerToken, getLoadingState, pickTimeWithAMPM, setLoadingState } from '../../../functions';
import axios from 'axios';
import PDFPreview from '../../PDFPreview';
import verticalicon from '../.././../images/verticalicon.svg';
import ToastMessage from '../../ToastMessage';
import AirLinesTRLoop from '../airlines-compo/AirLinesTRLoop';


// import images
import arrowicon from '../../../images/arrowicon.svg';
import { PaymentList } from './PaymentList';
import { useAuth } from '../auth/AuthProvider';
import toast from 'react-hot-toast';
import ConfirmationPopup from '../../Modals/ConfirmationPopup';


const BookingDetail = () => {
  const { id } = useParams();
  const [bookingData, setBookingData] = useState()
  const { isLoading, loadingKey } = getLoadingState();
  const [showToast, setShowToast] = useState('show')
  const [toastMessage, setToastMessage] = useState(null);
  const { token } = useAuth()
  const [targetBooking, setTargetBooking] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  // console.log('token.role', token.role);

  useEffect(() => {
    setLoadingState(true, 'BOOKING_DETAILS');
    axios.post(`${API_BASE_URL}/${token.role === 'admin' ? 'booking-detail' : 'agent-booking-detail'}`, {
      booking_id: id
    }, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    })
      .then(response => {
        // Handle the response data
        setLoadingState(false, '');
        setBookingData(response?.data?.data)

      }).catch(error => {
        // Handle errors
        console.error(error);
        setLoadingState(false, '');
      });
  }, [])

  const handalCancel = (e, id) => {
    setShowConfirm(true)
    setTargetBooking(e)
  };

  const handleConfirm = (e) => {
    e.preventDefault()
    setShowConfirm(false)
    setLoadingState(true, 'CANCEL_BOOKING');
    const tid = toast.loading("Please wait...");
    axios.get(`${API_BASE_URL}/cancelbooking/${targetBooking}`, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data
      toast.success('Ticket cancelled successfully!');
      toast.dismiss(tid);
      setLoadingState(false, '');
      // setToastMessage({ type: 'success', message: 'Ticket cancelled successfully!' });
      setTimeout(() => {
        window.location.reload();
      }, 2000);

    }).catch(error => {
      // Handle errors
      // setToastMessage({ type: 'failde', message: error.response.data.message });
      toast.error(error.response.data.message, {
        id: tid,
      });
      setShowToast('show')
      setLoadingState(false, '');


    });
  }





  // console.log("bookingData.cancel_at",bookingData?.cancel_at);
  return (
    <>
      {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={showToast} setShow={setShowToast} />}
      <div className="main_heading mb-3 text-center">
        <h4>Booking details</h4>
        {/* <div className="d-flex ms-auto align-items-center">
          <Link to="#" className="me-3 fontsize14 fontweight600">
            Print
          </Link>
          <Link to={`https://dnst.dnsmanpower.com/api/booking-pdf/${id}`} className=" btn greenbg text-white fontsize14 fontweight700" style={{ minWidth: '160px', maxWidth: '160px' }}>
            Download pdf
          </Link>
          <Dropdown className={''} align={'end'} autoClose="outside">
            <Dropdown.Toggle className={''} variant={''} id="dropdown-autoclose-outside"><img className={'img-fluid'} src={verticalicon} alt={'icon'} /></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className={'dropdown-item'} onClick={(e) => handalCancel(bookingData?.data?.id)}>
                {isLoading && loadingKey === 'CANCEL_BOOKING' ?
                  <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                  :
                  'Cancel Booking'
                }
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
      </div>
      <Row>

        {!bookingData ? (
          <div className={'mt-3 w-100 text-center'}>
            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
          </div>
        ) :
          (
            <Col className='m-auto mt-3' xxl={10} xl={10} lg={11}>
              <div className="main_heading d-flex align-items-center">
                <div className="d-block">
                  <p className='m-0 fontsize16'> <strong>Booking Date : </strong>{bookingData?.data?.booking_date}</p>
                  <small className='d-flex m-0'>
                    {bookingData?.data?.added_by && <p className='m-0 P-0 me-2'><span className=''><strong>Booked By : </strong></span> {bookingData?.data?.added_by?.name}</p>}
                    {bookingData?.data?.edit_by && <p className='m-0 P-0 me-2'><span className=''><strong>Edited By : </strong></span> {bookingData?.data?.edit_by?.name}</p>}
                    {bookingData?.data?.cancel_by && <p className='m-0 P-0 me-2'><span className=''><strong>Canceled By : </strong></span> {bookingData?.data?.cancel_by?.name}</p>}
                  </small>
                </div>
                <div className="d-flex ms-auto align-items-center">
                  <Link to={`https://dnst.dhilluandsahi.com/api/booking-pdf/${id}`} className=" btn greenbg text-white fontsize14 fontweight700" style={{ minWidth: '160px', maxWidth: '160px' }}>
                    Download pdf
                  </Link>
                  {bookingData?.data?.confirmed == 0 &&
                    <Dropdown className={''} align={'end'} autoClose="outside">
                      <Dropdown.Toggle className={''} variant={''} id="dropdown-autoclose-outside"><img className={'img-fluid'} src={verticalicon} alt={'icon'} /></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item className={'dropdown-item'} onClick={(e) => handalCancel(bookingData?.data?.id)}>
                          {isLoading && loadingKey === 'CANCEL_BOOKING' ?
                            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                            :
                            'Cancel Booking'
                          }
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>}
                </div>
              </div>
              <hr className='mt-1' />
              <div className="mb-3 d-flex">
                <div>
                  <img src={bookingData?.groupdata?.airline?.logo} alt='air line logo' style={{ height: '100% ', maxHeight: '80px' }} />
                </div>
                <div className="d-block text-end ms-auto">
                  <h2 className='fontsize20'>Confirmation Number</h2>
                  <p className={'mb-0 ms-auto fontsize18 paragraphtext'}>{bookingData?.data?.pnr}</p>
                  <Badge
                    bg=""
                    className={`p-3 py-2 fontsize16 fontweight600 ${(bookingData?.data?.confirmed === '1' && bookingData?.data?.cancel_status === '0') ? 'successcolorbg whitetext' : (bookingData?.data?.confirmed === '0' && bookingData?.data?.cancel_status === '0') ? 'warninglighterbg yellowtext' : bookingData?.data?.cancel_status === '1' && 'errorlighterbg redtext'}`}
                  >
                    {bookingData?.data?.cancel_status === '1' ? 'Cancelled' : bookingData?.data?.confirmed === '1' ? 'Confirmed' : bookingData?.data?.confirmed === '0' && 'Hold'}
                  </Badge>
                  {(bookingData.cancel_status !== '1' && bookingData?.cancel_at) ? <p className='fontweight700 redtext mt-1'>Ex. at {pickTimeWithAMPM(bookingData?.cancel_at)}</p> : null}
                </div>
              </div>
              <hr />
              <Row className="mb-4" >
                <Col xxl={6} xl={6} lg={6}>
                  <div className="">
                    <p className='me-2'><strong>View Pdf : </strong><Link to={bookingData?.data?.pdf_view_link} target='_blank'>View link</Link></p>
                  </div>
                </Col>
                <Col xxl={6} xl={6} lg={6}>
                  <div className="">
                    <p className='me-2'><strong>Sold Price : </strong>{bookingData?.data?.final_total || 0}</p>
                    <p className='me-2'><strong>Recieved : </strong>{bookingData?.data?.received_amount || 0}</p>
                    <p className='me-2'><strong>Remaining : </strong>{bookingData?.data?.remaining_amount || 0}</p>
                  </div>
                </Col>
                <div>
                  <p className='m-0 fontsize12 redtext'><strong>Remarks : </strong>{bookingData?.data?.remarks}</p>
                </div>
              </Row>
              <hr />
              <Card className='' style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
                <Card.Header className='px-3 py-3 d-flex' style={{ background: 'transparent', border: 'none', }}>
                  <h5 className={'fontsize18 blacktext fontweight600'}>Payments Details</h5>
                  {/* <div className="d-flex ms-auto">
                <p className='me-2'>Total : {bookingData?.data?.total_amount || 0}</p>
                <p className='me-2'>Recieved : {bookingData?.data?.received_amount || 0}</p>
                <p className='me-2'>Remaining : {bookingData?.data?.remaining_amount || 0}</p>
              </div> */}
                </Card.Header>
                <Card.Body className={'p-0 mb-3'}>
                  <Table responsive={'sm'} className={'mb-0 allView'}>
                    <thead className={'thead-dark'}>
                      <tr>
                        <th>Added by</th>
                        <th>Rec. Amount</th>
                        <th>Rec .Date</th>
                        <th>Description</th>
                        {token.role === 'admin' && <th>Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {bookingData?.data?.payments?.map((val, i) => {
                        return (
                          <PaymentList
                            key={i}
                            id={val.id}
                            added_by={val.added_by?.name ? val.added_by?.name : ''}
                            delete_by={val.delete_by?.name ? val.delete_by?.name : ''}
                            edit_by={val.edit_by?.name ? val.edit_by?.name : ''}
                            receive_amount={val.receive_amount}
                            receive_date={val.receive_date}
                            text={val.text}
                          />
                        )
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
                {/* <Card.Footer className='px-3 px-lg-4 py-3 text-end' style={{ background: 'transparent', }}>
              <Link to={'#'} className={'fontsize14 blacktext fontweight600 text-decoration-none'}>View All <img className={'img-fluid'} src={arrowicon} alt={'icon'} /></Link>
            </Card.Footer> */}
              </Card>

              {/* <div style={{ background: 'lightgray', width: "100%" }}>
            {isLoading && loadingKey === 'BOOKING_DETAILS' ?
              <div className={'mt-3 w-100 text-center'}>
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
              </div>
              :
              <iframe src={bookingData?.data?.pdf_view_link} width="100%" height="900px"></iframe>
            }
          </div> */}
            </Col>
          )}

      </Row>
      {showConfirm &&
        <ConfirmationPopup show={showConfirm} setShow={setShowConfirm} handleConfirm={handleConfirm} />
      }
    </>
  )
}

export default BookingDetail
